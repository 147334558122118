import Enterprise from '@assets/enterprise.png';
import { BTPaper } from "@components/atoms/bt-paper/bt-paper";
import { BillingPeriod } from "@components/subscription-selection/subscription-selection";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { activeSubscriptionSelector, selectUserObject } from "@reducers/UserSelectors";
import { useGetPlanMapMutation } from "@reducers/backend-api/backend-api";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import useObserver from "@reducers/hooks/use-observer";
import { AnalyticsService } from "@services/analytics-service";
import { useAppSelector } from "@store/hooks";
import { namespace } from "config/constants";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlanDescriptor, appConfig } from "../../config/app.config";
import { theme } from '../../theme';
import { ButtonType } from "../bluesnap-button/bluesnap-button";
import planBg from './background-flow.png';
import classes from './plan-selection.module.scss';
import ChargebeeButton from '@components/chargebee-button/chargebee-button';



const BlueTicksSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(19px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#a9e5ff' : '#a9e5ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18,
        height: 18,
        background: 'var(--default-background)'
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#53BDEB' : '#53BDEB',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export interface PlanSelectionProps {

}

export interface PlanSelectionState {
    selectedPlan?: any;
    loading?: boolean;
    sizeClass: string;
    buttonGroupSize: 'small' | 'medium';
    buttonGroupOrientation: 'horizontal' | 'vertical';
}
interface PaymentPlan {
    best?: boolean;
    name: string;
    planKey: string;
    key: string;
    limitations: any[];
    benefits: any[];
    common: any[];
    upgrade?: boolean;
    enterprise?: boolean;
    tip?: string;
}

export const PlanSelection = () => {
    const { t } = useTranslation(namespace);

    const extensionPricing = useMemo(() => {
        return t('planSelection.extensionPricing', { returnObjects: true });
    }, [t]);

    const taskPricing = useMemo(() => {
        return t('planSelection.taskPricing', { returnObjects: true });
    }, [t]);

    const planDescription: any = appConfig.pricingOptions === 'extension' ? extensionPricing : taskPricing;

    const analyticsService: AnalyticsService = useAnalyticsService();

    const resizeElement: React.RefObject<HTMLDivElement> = createRef();
    const user = useAppSelector(selectUserObject);
    const activeSubscription = useAppSelector(activeSubscriptionSelector);
    const isGettingPaymentUrl = useAppSelector(state => state.PaymentFormReducer.isGettingPaymentUrl);
    const [getPlanMap, { isLoading, data: planMap }] = useGetPlanMapMutation();
    const [_planDuration, setPlanDuration] = useState<BillingPeriod | undefined>();
    const [_seatCount, setSeatCount] = useState<number | undefined>();
    const [state, setState] = useState<PlanSelectionState>({
        sizeClass: '',
        buttonGroupSize: 'small',
        buttonGroupOrientation: 'horizontal',
    });
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlanDuration(event.target.checked ? 'month' : 'year');
    };
    const planDuration = useMemo(() => _planDuration ?? activeSubscription?.billingPeriod ?? 'year', [_planDuration, activeSubscription?.billingPeriod])
    const monthlyBilling = useMemo(() => planDuration === 'month', [planDuration]);

    const seatOptions: number[] = useMemo(() => Object.keys(planMap ?? {})
        .map(planName => planMap[planName])
        .filter(plan => plan.intervalUnit === planDuration)
        .reduce((_seatOptions, plan) => [
            ...new Set([..._seatOptions, plan.seatCount])
        ], [])
        .filter(x => x)
        .sort((a, b) => a - b)
        , [planMap, planDuration]);

    const seatCount = useMemo(() => _seatCount ?? activeSubscription?.seatCount ?? seatOptions[0] ?? 1, [_seatCount, activeSubscription?.seatCount, seatOptions])

    const activePlanMap: Record<string, Record<string, PlanDescriptor>> = useMemo(() => Object.keys(planMap ?? {})
        .map(planId => ({
            ...planMap[planId],
            id: planId
        }))
        .filter(plan => plan.seatCount === seatCount && plan.intervalUnit === planDuration)
        .reduce((activePlans, plan) => ({
            ...activePlans,
            [plan.planType]: {
                ...(activePlans[plan.planType] ?? {}),
                [plan.bluesnapType]: plan
            }
        }), {})
        , [planMap, seatCount, planDuration])

    useEffect(() => {
        getPlanMap();
    }, [])


    useObserver({
        element: resizeElement.current,
        callback: (entries: ReadonlyArray<ResizeObserverEntry>) => {
            const [entry] = entries;
            let sizeClass = '';
            let buttonGroupSize: 'small' | 'medium' = 'medium';
            let buttonGroupOrientation: 'horizontal' | 'vertical' = 'horizontal';
            if (entry.contentRect.width < 945) {
                sizeClass = classes.small;
                buttonGroupSize = 'small';
            }

            if (entry.contentRect.width < 450) {
                buttonGroupOrientation = 'vertical';
            }

            setState({
                ...state,
                sizeClass,
                buttonGroupSize,
                buttonGroupOrientation
            })
        }
    })

    const updatePlanDuration = (event: React.MouseEvent<HTMLElement, MouseEvent>, newPlanDuration: any) => {
        console.log(`Plan duration: ${planDuration}`);
        setPlanDuration(newPlanDuration);
    }

    const subscriptionBox = (index: number, paymentPlanData: PaymentPlan) => {
        const planAndContract: Record<string, PlanDescriptor> = activePlanMap[paymentPlanData.key];
        const plan = planAndContract?.plan;
        const planPrice = plan?.price;
        const displayedPrice = Math.round(planPrice / (plan?.intervalUnit === 'month' ? 1 : 12));
        const activePlanSeatCount = activeSubscription?.seatCount ?? 1;
        const planSeatCount = plan?.seatCount ?? 1;
        const intervalMonths = plan?.intervalUnit === 'month' ? 1 : 12;
        const activeIntervalMonths = activeSubscription?.billingPeriod === 'month' ? 1 : 12;
        const activeSubscriptionOrder = activeSubscription?.order ?? 0;
        const planOrder = plan?.order ?? 0;


        // Calculate the scores based on the subscription order, seat count, and interval months
        let activeScore = activeSubscriptionOrder * 1000 + activePlanSeatCount * 100 + activeIntervalMonths;
        let planScore = planOrder * 1000 + planSeatCount * 100 + intervalMonths;

        let CTA: string;
        let buttonType: ButtonType;
        let upgrade = false;

        const isCurrentPlan = planScore === activeScore;
        // Compare the scores
        if (planScore > activeScore) {
            CTA = t('planSelection.upgrade');
            buttonType = ButtonType.Upgrade;
            upgrade = true;
        } else if (isCurrentPlan) {
            CTA = t('planSelection.currentPlan');
            buttonType = ButtonType.Current;
        } else {
            CTA = t('planSelection.downgrade');
            buttonType = ButtonType.Downgrade;
        }

        let actionButton;
        if (!paymentPlanData.enterprise) {
            if (isLoading || (!plan && index > 0)) {
                actionButton = <CircularProgress />;
            } else {
                actionButton =
                    <Box data-testid={`subscription-upgrade-button-${paymentPlanData.name}`}>
                        <ChargebeeButton
                            plan={paymentPlanData.planKey}
                            duration={intervalMonths === 1 ? "Monthly" : "Yearly"}
                            label={CTA}
                            disabled={isCurrentPlan}
                            seats={seatCount}
                        />
                    </Box>
            }
        } else {
            actionButton = <Box />;
        }

        return <BTPaper
            key={index}
            className={[classes.planContainer, upgrade ? classes.selected : ''].join(' ')}
            style={{ backgroundImage: upgrade ? `url(${planBg})` : '', backgroundSize: 'cover' }}
        >
            <div className={classes.planName} style={{ marginBottom: theme.spacing(1) }}> {paymentPlanData.name} </div>
            {!paymentPlanData.enterprise && <div>
                <span className={classes.currency}>{planPrice ? '$' : ''}</span>
                <span className={classes.price}>{displayedPrice || '0$'}</span>
                {planPrice && <span>/ {t('planSelection.month')}</span>}
            </div>}
            {paymentPlanData.enterprise && <Box>
                <img src={Enterprise} alt={t('planSelection.enterprise')} style={{ height: "83px" }} />
            </Box>}
            {paymentPlanData.key !== "Free" &&
                <div style={{ opacity: planPrice ? 1 : 0, marginBottom: theme.spacing(2), color: upgrade ? "#fff" : 'initial' }} >
                    {planDuration === 'month' ? t('planSelection.perMonth') : t('planSelection.billedAnnually')}
                </div>
            }


            {paymentPlanData.key === "Free" && <Box>
                <div style={{ marginBottom: theme.spacing(2), color: 'initial' }} >
                    {t('pricing.freeForever')}
                </div>
            </Box>}

            <div style={{
                display: 'flex',
                gap: '18px',
                flexDirection: 'column',
                paddingLeft: "25px",
                margin: "12px 0",
                minHeight: "310px",
            }}>
                {
                    ([
                        ...paymentPlanData.benefits,
                        ...paymentPlanData.limitations,
                        ...paymentPlanData.common
                    ]).map((attribute: any, index: number) =>
                        <div className={`${classes.attributesContainer} ${upgrade ? classes.upgradePlan : ''}`} key={`${paymentPlanData.name}-attribute-${index}`} dangerouslySetInnerHTML={{ __html: attribute }}>
                        </div>)
                }
            </div>
            {(user ?
                <Box>
                    {actionButton}
                    {paymentPlanData.enterprise &&
                        <Button href="https://tudoboard.com/contact" className={classes.enterprise}>
                            {t('planSelection.contactSales')}
                        </Button>
                    }
                </Box> :
                <Button
                    className={classes.selectPlanButton}
                    href="/login?redirect=/account-subscription"
                    style={{
                        backgroundColor: '#2C2E2F',
                        color: '#ffffff'
                    }}>{t('planSelection.loginToBuy')}
                </Button>)
            }

            <Box mt={2} fontSize={14}>
                <div style={{ color: "#003750" }}>{t(paymentPlanData?.tip)}</div>
            </Box>

            {(paymentPlanData.best && upgrade) &&
                <span className={classes.mostPopular}>{t('planSelection.mostPopular')}</span>}
        </BTPaper>;
    }

    console.log('isGettingPaymentUrl', isGettingPaymentUrl);

    return <>
        {isGettingPaymentUrl ? <div style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
            height: "80vh"
        }}>
            <CircularProgress />
        </div> :
            <div ref={resizeElement} className={state.sizeClass}>
                {/* <PaymentForm /> */}

                <Stack className={classes.actionsContainer} direction="row">
                    <FormControl sx={{ width: 200 }}>
                        <div style={{ color: 'var(--secondary-text-color)', fontSize: '14px', marginBottom: '10px' }}>{t('planSelection.teamSize')}</div>
                        <Select
                            data-testid='buy-subscription-view-team-size-select'
                            sx={{ width: '125px', height: '36px', background: 'var(--background-default)', borderRadius: '8px', border: 'none!important' }}
                            labelId="seat-count-select-label"
                            id="seat-count-select"
                            value={seatCount}
                            IconComponent={ExpandMoreIcon}
                            onChange={(event) => {
                                analyticsService.event(`set_team_size`, {
                                    category: "buySubscriptionView",
                                    action: `set team size ${event.target.value.toString()}`,
                                });
                                setSeatCount(parseInt(event.target.value.toString()))
                            }}
                        >
                            {(seatOptions ?? []).map((option, index) => <MenuItem data-testid={`buy-subscription-view-team-size-select-option-${option}`} key={index} value={option}>{option}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',

                    }}>
                        <div style={{ color: 'var( --secondary-text-color)', fontSize: '14px', marginBottom: '10px' }}>{t('planSelection.billingPeriod')}</div>
                        <Stack sx={{ height: '36px', background: 'var(--background-default)', borderRadius: '8px', padding: '0 20px;' }} direction="row" spacing={1} alignItems="center">
                            <Typography sx={{ color: 'var(--billing-blue-title)', fontSize: '14px', fontWeight: 700 }}>{t('planSelection.annually')}</Typography>
                            <BlueTicksSwitch
                                data-testid={`buy-subscription-view-team-size-select-option-${planDuration}`}
                                sx={{ margin: '0 10px' }}
                                checked={monthlyBilling}
                                color="primary"
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onClick={(e) => {
                                    analyticsService.event(`set_billing_period_${monthlyBilling ? 'anually' : 'monthly'}`, {
                                        category: "buySubscriptionView",
                                        action: `set billing period ${monthlyBilling ? 'anually' : 'monthly'}`,
                                    });
                                    updatePlanDuration(e, monthlyBilling ? 'year' : 'month')
                                }} />
                            <Typography sx={{ color: 'var(--billing-blue-title)', fontSize: '14px' }}>{t('planSelection.monthly')}</Typography>
                        </Stack>
                    </Box>
                </Stack>
                {activePlanMap ? <div className={classes.planListContainer}>
                    {
                        planDescription?.map((plan, index) => subscriptionBox(index, plan))
                    }
                </div> : <Box>
                    <CircularProgress />
                </Box>}
            </div>
        }
    </>
}

export default PlanSelection;