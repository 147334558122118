import { FormState } from "@components/access-form/access-form";
import BTThemeProvider from "@components/bt-theme-provider";
import GuardedRoute from "@components/guarded-route/guarded-route";
import ManageLabels from "@components/manage-labels/manage-labels";
import {
  Box,
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { SubscriptionSuccessOptions } from "@reducers/WhatsappThunks";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { globalHistory } from "@services/global-history";
import AccountInvoiceView from "@views/account-invoice-view/account-invoice-view";
import AccountLangView from "@views/account-lang/account-lang";
import AccountNotificationsView from "@views/account-notification/account-notification";
import AccountSecurityView from "@views/account-security/account-security";
import AccountWorkspacesView from "@views/account-workspaces/account-workspaces";
import JoinBoard from "@views/join-board/join-board";
import JoinWorkSpace from "@views/join-workspace/join-workspace";
import { useEffect, useState } from "react";
import { unstable_HistoryRouter as HistoryRouter, Navigate, Route, Routes } from "react-router-dom";
import '../color-scheme.css';
import { AnalyticsService } from "../services/analytics-service";
import AccountSubscriptionView from "./views/account-subscription-view/account-subscription-view";
import AccountView from "./views/account-view/account-view";
import ApiPlaygroundView from "./views/api-playground-view/api-playground-view";
import MessageCalendar from "./views/calendar-view/message-calendar";

import JoinTaskBoard from "./views/join-task-board/join-task-board";
import LoginView from "./views/login-view/login-view";
import ResetPasswordView from "./views/reset-password-view/reset-password-view";
import SnippetsView from "./views/snippets-view/snippets-view";
import SubscriptionView from "./views/subscription-view/subscription-view";
import TaskView from "./views/task-view/task-view";
import TemplatesView from "./views/templates-view/templates-view";
import ThankYouView from "./views/thank-you-view/thank-you-view";
import WebHookGuideView from "./views/webbook-guide-view/webbook-guide-view";
import "./website.module.scss";
// import AdminWorkspaceView from "@views/admin-workspace/admin-workspace";
import InviteUserModal from "@components/invite/InviteUserModal";
import AdminAPIView from "@views/admin-api/admin-api";
import AdminBillingView from "@views/admin-billing/admin-billing";
import ForbiddenAccessView from "@views/forbidden-access-view/forbidden-access-view";
import FormPreviewView from "@views/form-preview/form-preview-view";
import InvoiceView from "@views/invoice-view/invoice-view";
import PageNotFound from "@views/page-not-found/page-not-found";

import CampaignsView from "@components/bt-bulk-dashboard/campaigns-view";
import NotificationsDrawer from "@components/tasks/extraData/notifications/notifications-drawers";
import AccountDevices from "@views/account-devices/account-devices";
import AnalyticsView from "@views/analytics-view/analytics-view";
import CellMockDataView from "@views/cell-mock-data-view/cell-mock-data-view";
import NewTabView from "@views/new-tab-view/new-tab-view";
import NewTabView2 from "@views/new-tab-view2/new-tab-view";
import QrView from "@views/qr-view/qr-view";
import BtQuickPanelView from "@views/quick-panel-view/quick-panel-view";
import UninstallView from "@views/uninstall-view/uninstall-view";
import UserManagementView from "@views/user-management/user-management";
import WhatsappView from "@views/whatsapp-view/whatsapp-view";
import WizardView from "@views/wizard-view/wizard-view";
import WorkspaceView from "@views/workspace-view/workspace-view";
import { appConfig } from "config/app.config";
import CampaignsViewWithContacts from "./views/campaigns-view-with-contacts/campaigns-view-with-contacts";

import TrialModal from "@components/modals/trial-modal";
import { Popup } from "@components/popup/popup";
import { CampaignManagementView } from "@views/campaign-management-view/campaign-management-view";
import Support from "@views/support/support";
import { UnifiedAdminBilling } from "@views/unified-admin-billing/unified-admin-billing";
import WebHookGuideMake from "@views/webhook-guide-make/webbook-guide-make";
import WebHookGuideZapier from "@views/webhook-guide-zaiper/webbook-guide-zapier";
import { createGlobalStyle } from 'styled-components';
import PaymentThankYouPopup from "@components/payment-form/payment-thank-you-popup";
import ConditionalWrapper from "injections/ConditionalWrapper";
import { RootState } from "@store/index";

export interface WebsiteComponentProps { }

export interface WebsiteComponentState {
  successDialogOpen: boolean;
  subscriptionSuccessOptions?: SubscriptionSuccessOptions;
}

const GlobalStyle = createGlobalStyle`
  #g_a11y_announcement {
    display:none
  }
`;

export default function WebsiteComponent(props: WebsiteComponentProps) {
  const analyticsService: AnalyticsService = useAnalyticsService();
  const [state, setState] = useState<WebsiteComponentState>({
    successDialogOpen: false,
    subscriptionSuccessOptions: undefined,
  });


  useEffect(() => {

    console.log('----')
    console.log('loading google analytics')
    console.log(appConfig.GA_ID)



    if (appConfig.GA_ID) {
      // const gaScript = document.createElement('script');
      // gaScript.async = true;
      // gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${appConfig.GA_ID}`;
      // gaScript.onload = () => {
      //   window.dataLayer = window.dataLayer || [];
      //   function gtag(...args: any[]) { window.dataLayer.push(args); }
      //   window.gtag = gtag;
      //   gtag('js', new Date());
      //   gtag('config', appConfig.GA_ID);
      //   gtag('config', appConfig.AW_ID, { groups: 'all' });
      //   gtag('config', appConfig.WS_ID);
      // };
      // document.head.appendChild(gaScript);


      // Create a script element for Google Tag Manager
      var gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${appConfig.GA_ID}`;
      document.head.appendChild(gtmScript);

      // Inline script content
      var inlineScript = document.createElement('script');
      inlineScript.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', '${appConfig.GA_ID}');
      gtag('config', '${appConfig.AW_ID}', { groups: 'all' });
      gtag('config', '${appConfig.WS_ID}');
      `;
      document.head.appendChild(inlineScript);



    }



    // IT CUSING GOOGLE ANALYTICS NOT TO SEND EVENTS
    /*
    if (appConfig.GTM_ID) {
      // Create a script tag for the Google Tag Manager (GTM)
      const gtmScript = document.createElement('script');
      gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${appConfig.GTM_ID}');`;
      document.head.appendChild(gtmScript);

      // Existing Google Analytics initialization code
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${appConfig.GTM_ID}');
        `;
      document.body.appendChild(gaScript);
    }
    */

    // Google Analytics
    // if (appConfig.GA_ID) {
    //   const gaScript = document.createElement('script');
    //   gaScript.async = true;
    //   gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${appConfig.GA_ID}`;
    //   gaScript.onload = () => {
    //     window.dataLayer = window.dataLayer || [];
    //     function gtag(...args: any[]) { window.dataLayer.push(args); }
    //     window.gtag = gtag;
    //     gtag('js', new Date());
    //     gtag('config', appConfig.GA_ID);
    //     gtag('config', appConfig.AW_ID, { groups: 'all' });
    //     gtag('config', appConfig.WS_ID);
    //   };
    //   document.head.appendChild(gaScript);
    // }





    if (appConfig.FB_PIXEL_ID) {
      const fbScript = document.createElement('script');
      fbScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${appConfig.FB_PIXEL_ID}');
        fbq('track', 'PageView');
      `;
      document.head.appendChild(fbScript);
    }

    // Hotjar
    if (appConfig.HJ_ID) {
      const hotjarScript = document.createElement('script');
      hotjarScript.innerHTML = `
        (function (h, o, t, j, a, r) {
          h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
          h._hjSettings = { hjid: ${appConfig.HJ_ID}, hjsv: 6 };
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script'); r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      `;
      document.head.appendChild(hotjarScript);
    }

    // TikTok
    if (appConfig.TIKTOK_ID) {
      const tiktokScript = document.createElement('script');
      tiktokScript.innerHTML = `
    !function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
    )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
    
      ttq.load('${appConfig.TIKTOK_ID}');
      ttq.page();
    }(window, document, 'ttq');
    `
      document.body.appendChild(tiktokScript);
    }

    // Clarity
    if (appConfig.CLARITY_ID) {
      const clarityScript = document.createElement('script');
      clarityScript.innerHTML = `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${appConfig.CLARITY_ID}");
      `
      document.body.appendChild(clarityScript);
    }
  }, []);

  useEffect(() => {
    analyticsService.pageView(window.location.pathname);
  }, [analyticsService]);

  const closeSuccesDialog = () => {
    window.open("https://web.whatsapp.com/", "_blank");
    setState({
      ...state,
      successDialogOpen: false,
    });
  };

  const pageContent = () => {
    const supportedUserId = new URLSearchParams(window.location.search).get('supportedUserId');
    const readOnly = supportedUserId ? true : false;
    return (
      <Box>
        <GlobalStyle />
        <BTThemeProvider>
          <HistoryRouter history={globalHistory}>
            {
              <Routes>
                <Route path="/account" element={
                  <GuardedRoute
                    element={<AccountView readOnly={readOnly} />}
                    auth={true}
                  />
                } />
                <Route path="/account-subscription" element={
                  <GuardedRoute
                    element={<AccountSubscriptionView />}
                    auth={true}
                  />
                } />
                <Route path="/account-invoices" element={
                  <GuardedRoute
                    element={<AccountInvoiceView />}
                    auth={true}
                  />
                } />

                <Route
                  path="/account-invoices"
                  element={
                    <GuardedRoute element={<AccountInvoiceView />} auth={true} />
                  }
                />
                {/* <Route
                  path="/admin-workspace"
                  element={
                    <GuardedRoute element={<AdminWorkspaceView />} auth={true} />
                  }
                /> */}
                <Route
                  path="/new-workspace"
                  element={
                    <GuardedRoute element={<WorkspaceView />} auth={true} />
                  }
                />
                <Route
                  path="/edit-workspace"
                  element={
                    <GuardedRoute element={<WorkspaceView editMode />} auth={true} />
                  }
                />
                <Route
                  path="/form-preview/:shareId"
                  element={<FormPreviewView />}
                />
                <Route
                  path="/admin-billing-old"
                  element={
                    <GuardedRoute element={<AdminBillingView readOnly={readOnly} />} auth={true} />
                  }
                />
                <Route
                  path="/user-management"
                  element={
                    <GuardedRoute element={<UserManagementView readOnly={readOnly} />} auth={true} />
                  }
                />
                <Route
                  path="/admin-billing"
                  element={
                    <GuardedRoute element={<UnifiedAdminBilling />} auth={true} />
                  }
                />
                <Route
                  path="/admin-api"
                  element={
                    <GuardedRoute element={<AdminAPIView />} auth={true} />
                  }
                />
                <Route
                  path="/login"
                  element={
                    <GuardedRoute
                      element={<LoginView initialState={FormState.Login} />}
                      redirect='/tasks'
                      auth={false}
                      redirectBack={false}
                    />
                  } />

                <Route path="/signup" element={
                  <GuardedRoute
                    element={<LoginView initialState={FormState.Signup} />}
                    redirect='/tasks'
                    auth={false}
                  />
                } />
                <Route path="/pricing" element={
                  <GuardedRoute
                    element={<SubscriptionView />}
                    auth={true}
                  />
                } />
                <Route path="/tasks" element={
                  <GuardedRoute
                    element={<TaskView />}
                    auth={true}
                  />
                } />
                <Route path="/mywork" element={
                  <GuardedRoute
                    element={<TaskView />}
                    auth={true}
                  />
                } />
                <Route path="/campaigns" element={
                  <GuardedRoute
                    element={<CampaignsView isWebsite={true} />}
                    auth={true}
                  />
                } />
                <Route path="/campaigns-management" element={
                  <GuardedRoute
                    element={<CampaignManagementView />}
                    auth={true}
                  />
                } />
                <Route path="/labels" element={
                  <GuardedRoute
                    element={<ManageLabels />}
                    auth={true}
                  />
                } />
                <Route
                  path="/reset-password"
                  element={<ResetPasswordView />}
                />
                <Route path="/reset-password" element={
                  <GuardedRoute
                    element={<LoginView initialState={FormState.ForgotPassword} />}
                    redirect='/login'
                    auth={false}
                  />
                } />

                <Route
                  path="/account-language"
                  element={
                    <GuardedRoute element={<AccountLangView />} auth={true} />
                  }
                />
                <Route
                  path="/forbidden-access"
                  element={<ForbiddenAccessView />}
                />
                <Route
                  path="/account-notification"
                  element={
                    <GuardedRoute
                      element={<AccountNotificationsView />}
                      auth={true}
                    />
                  }
                />
                <Route
                  path="/account-security"
                  element={
                    <GuardedRoute element={<AccountSecurityView />} auth={true} />
                  }
                />


                <Route
                  path="/devices"
                  element={
                    <GuardedRoute element={<AccountDevices />} auth={true} />
                  }
                />
                <Route
                  path="/thank-you"
                  element={<ThankYouView />}
                />
                <Route
                  path="/account-workspace"
                  element={
                    <GuardedRoute
                      element={<AccountWorkspacesView readOnly={readOnly} />}
                      auth={true}
                    />
                  }
                />
                <Route
                  path="/whatsapp-view"
                  element={<WhatsappView />}
                />
                <Route
                  path="/trialModal"
                  element={<TrialModal />}
                />
                <Route
                  path="/wizard-view"
                  element={<WizardView />}
                />

                <Route
                  path="/qr-view"
                  element={
                    <GuardedRoute
                      element={<QrView />}
                      auth={true}
                    />
                  }
                />
                <Route
                  path="/account-invoices"
                  element={
                    <GuardedRoute element={<AccountInvoiceView />} auth={true} />
                  }
                />
                {/* <Route
                  path="/admin-workspace"
                  element={
                    <GuardedRoute element={<AdminWorkspaceView />} auth={true} />
                  }
                /> */}
                <Route
                  path="/admin-billing"
                  element={
                    <GuardedRoute element={<AdminBillingView readOnly={readOnly} />} auth={true} />
                  }
                />
                <Route
                  path="/admin-api"
                  element={
                    <GuardedRoute element={<AdminAPIView />} auth={true} />
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <GuardedRoute
                      element={<LoginView initialState={FormState.Signup} />}
                      redirect="/account"
                      auth={false}
                    />
                  }
                />
                <Route
                  path="/pricing"
                  element={
                    <GuardedRoute element={<SubscriptionView />} auth={true} />
                  }
                />
                <Route
                  path="/tasks"
                  element={<GuardedRoute element={<TaskView />} auth={true} />}
                />
                <Route
                  path="/support"
                  element={<GuardedRoute element={<Support />} auth={true} />}
                />
                <Route
                  path="/campaignsWithContacts"
                  element={
                    <GuardedRoute element={<CampaignsViewWithContacts />} auth={true} />
                  }
                />

                <Route
                  path="/labels"
                  element={
                    <GuardedRoute element={<ManageLabels />} auth={true} />
                  }
                />
                <Route
                  path="/analytics"
                  element={
                    <GuardedRoute element={<AnalyticsView />} auth={true} role="admin" />
                  }
                />
                <Route
                  path="/notifications-drawer"
                  element={
                    <GuardedRoute element={<NotificationsDrawer />} auth={true} role="admin" />
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <GuardedRoute
                      element={
                        <LoginView initialState={FormState.ForgotPassword} />
                      }
                      redirect="/login"
                      auth={false}
                    />
                  }
                />
                <Route path="/api-playground" element={<ApiPlaygroundView />} />
                <Route path="/templates" element={<TemplatesView />} />
                <Route path="/webhook-guide" element={<WebHookGuideView />} />
                <Route path="/webhook-make-guide" element={<WebHookGuideMake />} />
                <Route path="/webhook-zapier-guide" element={<WebHookGuideZapier />} />
                <Route path="/thank-you" element={<ThankYouView />} />
                <Route path="/uninstall" element={<UninstallView />} />
                <Route path="/sharetable" element={<InviteUserModal />} />
                <Route
                  path="/join-task-board/:shareId"
                  element={
                    <GuardedRoute element={<JoinTaskBoard />} auth={true} />
                  }
                />
                <Route
                  path="/invoices/:invoiceId"
                  element={
                    <GuardedRoute element={<InvoiceView />} auth={true} />
                  }
                />
                <Route path="/join-board/:boardId" element={
                  <GuardedRoute
                    element={<JoinBoard />}
                    auth={true}
                  />
                } />
                <Route path="/join-workspace/:workspaceId" element={
                  <GuardedRoute
                    element={<JoinWorkSpace />}
                    auth={true}
                  />
                } />
                {/* <Route path="/" element={<Navigate to="/pricing" replace />} /> */}
                <Route path="*" element={<Navigate to="./login" replace />} />
                <Route path="/snippets" element={<SnippetsView />} />
                <Route
                  path="/calendar"
                  element={<MessageCalendar onClose={() => { }} />}
                />
                <Route
                  path="/page-not-found"
                  element={<PageNotFound />}
                />
                <Route
                  path="/extension-popup"
                  element={<Popup />}
                />
                <Route
                  path="/mock-data-view"
                  element={<CellMockDataView />}
                />

                <Route element={<LoginView initialState={FormState.Login} />} />

                <Route path="/bt-quick-panel-view" element={<BtQuickPanelView />} />
                <Route path="/new-tab-view" element={<NewTabView />} />
                <Route path="/new-tab-view2" element={<NewTabView2 />} />
              </Routes >
            }

            {/* <ConditionalWrapper shouldLoadSelector={(state: RootState) => state.PaymentFormReducer.open}>
              <PaymentForm />
            </ConditionalWrapper> */}
            <ConditionalWrapper shouldLoadSelector={(state: RootState) => state.PaymentFormReducer.paymentSuccess}>
              <PaymentThankYouPopup />
            </ConditionalWrapper>
          </HistoryRouter >
          {/* <Dialog open={state.successDialogOpen} onClose={closeSuccesDialog}>
            <DialogTitle>Subscription Added</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <img
                  alt=""
                  src={state.subscriptionSuccessOptions?.shareASaleUrl}
                  width="1"
                  height="1"
                />
                <div className={classes.bigTitle}>Thank you for subscribing!</div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={closeSuccesDialog}
                color="primary">
                Start Scheduling
              </Button>
            </DialogActions>
          </Dialog> */}
          {/* <SubscriptionUpgradePopup /> */}
          {/* <ChargebeeUpdatePaymentMethodPopup /> */}
        </BTThemeProvider >
      </Box>
    );
  };

  return pageContent();
}
